@font-face {
  font-family: "Rimouski";
  src: url("../fonts/rimouski\ sb.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

/* Body */
body {
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(to bottom right, #f3abb4, #ffffff) no-repeat;
  font-family: "Rimouski";
}

.container {
  max-width: 1200px;
  margin: auto;
}

/* Header */
.header {
  width: 100%;
  height: 80px;
  padding: 0 20px;
}

.header .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.header .nav .logo img {
  max-width: 60px;
  height: auto;
}

.header .nav .nav-list {
  list-style: none;
}

.header .nav .nav-list .nav-item {
  display: inline-block;
}

.nav-list .nav-item .nav-link {
  padding: 10px 20px;
  position: relative;
  transition: all 0.7s ease;
  text-decoration: none;
  color: #dc1e3d;
  font-size: 15px;
  font-weight: 400;
  opacity: 0.7;
  line-height: 24px;
}

.nav-list .nav-item .nav-link::after {
  content: "";
  position: absolute;
  border-radius: 50px;
  bottom: 10%;
  left: 10%;
  height: 3px;
  max-width: 80%;
  width: 0;
  background-color: #dc1e3d;
  transition: all 0.7s ease;
}

.nav-list .nav-item .nav-link:hover::after {
  width: 100%;
}

.nav-list .nav-item .nav-link:hover,
.nav-list .nav-item.active > .nav-link {
  opacity: 1;
}

.header .nav .nav-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-right .btn-action {
  padding: 10px 25px;
  border: none;
  background-color: #373336;
  border: solid 2px #373336;
  color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s background, 0.5s color;
}

.nav-right .btn-action:hover {
  background: transparent;
  color: #373336;
}

.nav-right .MuiInputBase-root {
  border-radius: 20px !important;
}

#language-select {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
}

#language-select,
.menu-language {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.flag {
  width: 25px;
}

.menu-container {
  display: none;
  font-size: 35px;
  color: #dc1e3d;
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .header .nav .nav-center,
  .header .nav .nav-right {
    display: none !important;
  }

  .menu-container {
    display: block;
  }

  .nav-box {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .nav-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    list-style: none;
  }

  .nav-list .nav-item .nav-link {
    line-height: 70px;
    font-size: 30px;
  }

  .nav-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

/* home */
.home {
  overflow: hidden;
  position: relative;
  padding: 0 20px;
}

.home .home-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home .home-container .moka {
  z-index: 10;
}

.home .home-container .moka h1 {
  font-size: 70px;
  line-height: 1.2;
  font-weight: 800;
  color: #373336;
  margin-bottom: 35px;
}

.home .home-container .moka h1 span {
  color: #dc1e3d;
}

.home .home-container .moka h1 span.pen-tool {
  position: relative;
  padding: 56px 40px 56px 20px;
}

.home .home-container .moka h1 span.pen-tool img {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  user-select: none;
  pointer-events: none;
}

[dir="rtl"] .home .home-container .moka h1 span.pen-tool img {
  top: 60px;
  right: 17px;
  transform: scaleX(-1);
}

.home .home-container .moka p {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.7px;
  color: #b56477;
  margin-bottom: 30px;
}

.home .home-container .moka .btn-group {
  display: flex;
  gap: 15px;
}

.home .home-container .moka .btn-group .home-btn {
  padding: 13px 25px;
  border-radius: 50px;
  text-decoration: none;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s background, 0.5s color;
}

.home .home-container .moka .btn-group .home-btn:hover {
  background: transparent;
  color: #dc1e3d;
}

.home .home-container .moka .btn-group .start-btn {
  color: #ffffff;
  background: #dc1e3d;
  border: solid 2px #dc1e3d;
}

.home .home-container .moka .btn-group .how-btn {
  color: #373736;
  background: transparent;
}

.home .home-container .moka-detail {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
  z-index: 0;
}

/* Moka Detail Background */
/* .home .home-container .moka-detail .bg {
    top: 50%;
    left: 25%;
    width: 200px;
    height: 200px;
    display: block;
    position: absolute;
    border-radius: 50%;
    filter: blur(90px);
    opacity: .75;
    transform-origin: 140px 0px;
}

.home .home-container .moka-detail .bg:nth-child(1) {
    transform: rotate(calc(90deg * 0)) translate(-50%, -50%);
    background-color: #fe7e6d;
}

.home .home-container .moka-detail .bg:nth-child(2) {
    transform: rotate(calc(90deg * 1)) translate(-50%, -50%);
    background-color: #ffc900;
}

.home .home-container .moka-detail .bg:nth-child(3) {
    transform: rotate(calc(90deg * 2)) translate(-50%, -50%);
    background-color: #84dfff;
}

.home .home-container .moka-detail .bg:nth-child(4) {
    transform: rotate(calc(90deg * 3)) translate(-50%, -50%);
    background-color: #db6b97;
} */

[dir="rtl"] .home .home-container .moka-detail .player svg {
  transform: scaleX(-1) !important;
}

.home .rocket {
  width: 100%;
  text-align: center;
  margin: 0 0 30px 0;
}

.home .rocket .rocket-icon {
  color: #373336;
  font-size: 40px !important;
  margin: 10px 0;
  cursor: pointer;
}

@media screen and (max-width: 1250px) {
  .home .home-container .moka h1 {
    font-size: 60px;
  }

  .home .home-container .moka-detail .player img {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .home .home-container .moka h1 {
    font-size: 45px;
  }

  .home .home-container .moka p {
    font-size: 12px;
  }
}

@media screen and (max-width: 850px) {
  .home .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .home .home-container .moka {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home .home-container .moka h1 {
    font-size: 60px;
    text-align: center;
  }

  .home .home-container .moka p {
    font-size: 16px;
    text-align: center;
  }

  .home .home-container .moka-detail {
    width: 70%;
  }
}

/* Tool We Use */

.tool-we-use {
  background: #f3c215;
  padding: 40px;
  overflow: hidden;
}

.title {
  font-size: 40px;
  margin: 0 0 20px 0;
  text-align: center;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 0 40px 0;
  text-align: center;
}

.tool-we-use .box-integrations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.tool-we-use .box-integrations .integration {
  position: relative;
  width: 10%;
}

.tool-we-use .box-integrations .integration .integration-icon,
.tool-we-use .box-integrations .integration .shadow-icon {
  background: #dc1e3d;
  color: #ffffff;
  font-size: 40px !important;
  text-align: center;
  width: 100%;
  padding: 25% 0;
  border: 2px solid #373336;
  border-radius: 8%;
}

.tool-we-use .box-integrations .integration .integration-icon {
  position: relative;
  z-index: 2;
}

.tool-we-use .box-integrations .integration .shadow-icon {
  position: absolute;
  z-index: 1;
  bottom: -4px;
  left: -3px;
}

@media screen and (max-width: 1100px) {
  .tool-we-use .box-integrations .integration .integration-icon,
  .tool-we-use .box-integrations .integration .shadow-icon {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 900px) {
  .tool-we-use .box-integrations {
    gap: 40px;
  }

  .tool-we-use .box-integrations .integration .integration-icon,
  .tool-we-use .box-integrations .integration .shadow-icon {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 700px) {
  .tool-we-use .box-integrations {
    gap: 30px;
  }

  .tool-we-use .box-integrations .integration .integration-icon,
  .tool-we-use .box-integrations .integration .shadow-icon {
    font-size: 20px !important;
  }
}

/* Main Features */

.main-features {
  background: #ffffff;
  padding: 40px;
  overflow: hidden;
}

.main-features .statistic {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  text-align: start;
}

.main-features .statistic .statistic-img,
.main-features .statistic .statistic-details {
  position: relative;
  flex: 1;
}

.main-features .statistic .statistic-img img {
  width: 100%;
  /* background: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.main-features .statistic .statistic-img .statistic-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 7px;
  background: #ffffff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.main-features .statistic .statistic-img .statistic-box-1 {
  /* top: 15%; */
  top: 5%;
}
.main-features .statistic .statistic-img .statistic-box-2 {
  /* top: 55%; */
  top: 40%;
}
.main-features .statistic .statistic-img .statistic-box-3 {
  /* top: 5%; */
  top: 0;
  right: 10%;
}
[dir="rtl"] .main-features .statistic .statistic-img .statistic-box-3 {
  right: auto;
  left: 10%;
}

.main-features .statistic .statistic-img .statistic-box-4 {
  /* top: 40%; */
  top: 30%;
  width: 200px;
  right: 5%;
}
[dir="rtl"] .main-features .statistic .statistic-img .statistic-box-4 {
  right: auto;
  left: 5%;
}
@media screen and (max-width: 500px) {
  .main-features .statistic .statistic-img .statistic-box-4 {
    display: none;
  }
}

.main-features .statistic .statistic-img .statistic-box .top {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-features .statistic .statistic-img .statistic-box .top .box-icon {
  color: #ffffff;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 13px;
}

.main-features .statistic .statistic-img .statistic-box-1 .top .box-icon {
  background: #f3c215;
}
.main-features .statistic .statistic-img .statistic-box-2 .top .box-icon {
  background: #dc1e3d;
}
.main-features .statistic .statistic-img .statistic-box-3 .top .box-icon {
  background: #304399;
}

.main-features .statistic .statistic-img .statistic-box .top .box-title {
  font-size: 20px;
  color: #304399;
}

.main-features .statistic .statistic-img .statistic-box .box-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 600;
}

.main-features .statistic .statistic-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-features .statistic .statistic-details .feature {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 5%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.main-features .statistic .statistic-details .feature .feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
  font-size: 25px !important;
  color: #ffffff !important;
  border-radius: 10px;
}

.main-features .statistic .statistic-details .feature .feature-icon-1 {
  background: #f3c215;
}
.main-features .statistic .statistic-details .feature .feature-icon-2 {
  background: #dc1e3d;
}
.main-features .statistic .statistic-details .feature .feature-icon-3 {
  background: #304399;
}

.main-features .statistic .statistic-details .feature .feature-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-features .statistic .statistic-details .feature .feature-text .feature-p {
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 1200px) {
  .main-features .statistic .statistic-details .feature .feature-icon {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 900px) {
  .main-features .statistic {
    flex-direction: column;
    gap: 0;
  }
}

/* Services One */
.services-one {
  background: #304399;
  color: #ffffff;
  padding: 40px;
}

.services-one .services-one-group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
}

.services-one .services-one-group .services-one-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  flex: 1 1;
}

.services-one .services-one-group .services-one-details .service-one-detail {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.services-one
  .services-one-group
  .services-one-details
  .service-one-detail
  .service-icon {
  color: #f3c215;
  font-size: 30px;
}

.services-one
  .services-one-group
  .services-one-details
  .service-one-detail
  .service-text {
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px 0 0;
}

/* Services Two */
.services-two {
  background: #ffffff;
  padding: 40px;
  overflow: hidden;
}

.services-two .services-header {
  width: 100%;
  margin: 0 0 20px 0;
}

.services-two .services-header h6 {
  text-transform: uppercase;
  margin: 0 0 20px 0;
}

.services-two .services-header h1 {
  font-size: 3rem;
}

.services-two .services-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.services-two .services-boxes .service-box {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.services-two .services-boxes .service-box-01 {
  background: #f5feeb;
}
.services-two .services-boxes .service-box-02 {
  background: #ebecfe;
}
.services-two .services-boxes .service-box-03 {
  background: #ebfafd;
}
.services-two .services-boxes .service-box-04 {
  background: #fdecf6;
}
.services-two .services-boxes .service-box-05 {
  background: #feecec;
}
.services-two .services-boxes .service-box-06 {
  background: #fdf4eb;
}

.services-two .services-boxes .service-box span {
  background: #000000;
  color: #ffffff;
  padding: 15px;
  border-radius: 15px 0;
}
[dir="rtl"] .services-two .services-boxes .service-box span {
  border-radius: 0 15px;
}

.services-two .services-boxes .service-box .service-content {
  padding: 20px;
}

.services-two .services-boxes .service-box .service-content h2 {
  font-size: 25px;
}

.services-two .services-boxes .service-box .service-content p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 20px 0;
}

@media screen and (max-width: 500px) {
  .services-two .services-header h1 {
    font-size: 2rem;
  }
}

/* Contact Us */
.contact-us {
  background: #dc1e3d;
  background-image: url("../img/contact.svg");
  background-repeat: no-repeat;
  background-position: top center;
  text-align: center;
  color: #ffffff;
  padding: 40px;
}

.contact-us .contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.contact-us .contact-btn {
  background: #000000;
  color: #ffffff;
  cursor: pointer;
  padding: 15px 30px;
  border-radius: 15px;
  border: solid 2px #000000;
  transition: 0.5s background, 0.5s color, 0.5s border;
}

.contact-us .contact-btn:hover {
  background: transparent;
  border-color: #ffffff;
}

/* Footer */
.footer {
  background: #dc1e3d;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 20px;
  padding: 20px;
}

.footer .left-footer {
  background: #f3c215;
  color: #304399;
  flex: 0.4 1;
  border-radius: 20px;
  padding: 40px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.footer .left-footer p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 20px 0;
}

.footer .left-footer .btn-contact {
  background: #304399;
  color: #ffffff;
  display: block;
  text-align: center;
  padding: 10px 15px;
  width: 150px;
  border: 2px solid #000000;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s background, 0.5s color, 0.5s border;
}

.footer .left-footer .btn-contact:hover {
  background: transparent;
  border-color: #304399;
}

.footer .right-footer {
  background: #304399;
  color: #ffffff;
  border-radius: 20px;
  flex: 1 1;
  padding: 40px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .right-footer .top {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.footer .right-footer .bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.footer .right-footer .email-part {
  flex: 0.5 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .right-footer .email-part img {
  width: 40%;
  filter: brightness(0) invert(1);
}

.footer .right-footer .bottom form {
  display: flex;
}

.footer .right-footer .bottom form input {
  background: #ffffffac;
  border: 0;
  outline: none;
  padding: 10px 15px;
  border-radius: 10px 0 0 10px;
  flex: 1;
}
[dir="rtl"] .footer .right-footer .bottom form input {
  border-radius: 0 10px 10px 0;
}

.footer .right-footer .bottom form button {
  background: #f3c215;
  border: 0;
  outline: none;
  padding: 10px 15px;
  border-radius: 0 10px 10px 0;
  font-family: "Rimouski";
  cursor: pointer;
}
[dir="rtl"] .footer .right-footer .bottom form button {
  border-radius: 10px 0 0 10px;
}

.footer .right-footer .bottom .copyright {
  font-weight: 500;
}

.footer .right-footer .bottom .copyright a {
  color: #ffffff;
  text-decoration: none;
}

.footer .right-footer .bottom .copyright a:hover {
  text-decoration: underline;
}

.footer .right-footer ul li {
  list-style: none;
  padding: 3px;
}

.footer .right-footer ul a {
  color: #ffffff;
  text-decoration: none;
}

.footer .right-footer .menu-part ul a:hover {
  text-decoration: underline;
}

.footer .right-footer .social-part ul li {
  float: left;
  font-size: 20px;
  padding: 5px;
}

@media screen and (max-width: 800px) {
  .footer .left-footer {
    flex: 1 1;
  }
  .footer .left-footer .btn-contact {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .footer .right-footer {
    font-size: 13px;
  }
}

/* Contact */
.form {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 78, 78, 0.516);
  z-index: 10;
  top: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form .form-container {
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 30px;
  width: 700px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
}

.form .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.5s;
}

.form .close-btn:hover {
  color: #dc1e3d;
}

.form .full-name {
  display: flex;
  gap: 20px;
}

.form .full-name .input-name {
  flex: 1;
}

.form .send-btn {
  display: flex;
  justify-content: center;
}

.form .send-btn button {
  background: #dc1e3d;
  color: #ffffff;
  border: 0;
  outline: none;
  padding: 15px;
  border-radius: 4px;
  font-family: "Rimouski";
  cursor: pointer;
  width: 30%;
  transition: background 0.5s;
}

.form .send-btn button:hover {
  background: #dc1e3dcf;
}

[dir="rtl"] .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
[dir="rtl"] .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
[dir="rtl"] .css-1d3z3hw-MuiOutlinedInput-notchedOutline > legend {
  left: inherit !important;
  right: 1.75rem !important;
  transform-origin: right !important;
  text-align: right;
}

.page {
  background: #ffffff;
  padding: 50px 20px;
}

.container-page {
  max-width: 1000px;
  margin: auto;
}

.page h1 {
  font-size: 50px;
  text-align: center;
  margin: 10px 0 20px 0;
}

.page section {
  margin: 30px 0;
}

.page h2 {
  font-size: 30px;
  margin: 5px 0;
}

.page p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.swal-link {
  color: #000000;
}

.social-media-links {
  max-width: 500px;
  margin: 30px auto 80px auto;
  padding: 20px;
}

.social-media-links h1 {
  text-align: center;
  margin: 0 0 80px 0;
  font-size: 40px;
  color: #dc1e3dcf;
}

.social-media-links .social-media {
  background: var(--social-background);
  text-decoration: none;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0;
  font-size: 17px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-media-links .social-media:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: 200% 200%;
  animation: moveGradient 2s ease infinite;
}

@keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.instagram {
  --social-background: linear-gradient(
    to bottom right,
    #6228d7,
    #ee2a7b,
    #f9ce34
  );
}
.facebook {
  --social-background: linear-gradient(to right, #5875b5, #3b5998);
}
.linkdin {
  --social-background: linear-gradient(to right, #0397e6, #0e8ed3, #0077b5);
}
.xtwitter {
  --social-background: linear-gradient(to right, #32383e, #23282d, #14171a);
}
.youtube {
  --social-background: linear-gradient(to right, #f63939, #f50000, #ff0000);
}
.whatsapp {
  --social-background: linear-gradient(to right, #21ed6c, #2adb6b, #25d366);
}
.phone {
  --social-background: linear-gradient(to right, #ed3b59, #dc1e3d);
}
